import React from 'react';
//import { Helmet } from "react-helmet"
import Layout from '../layout';
import { graphql } from 'gatsby';

import { GatsbyImage } from 'gatsby-plugin-image';
import SEO from '../components/SEO/SEO';

//import Img from "gatsby-image"

if (typeof window !== `undefined`) {
  require('bootstrap/dist/css/bootstrap.min.css');
}

export default function OurClients(props) {
  //const postEdges = props.data.allMarkdownRemark.edges;
  // const [state, setState] = React.useState({})

  return (
    <Layout>
      <SEO title="Our Clients" />
      <div className="container site-content">
        <div className="row">
          <h5>Here's the list of some of our many clients:</h5>
        </div>

        <div className="row mb-5">
          <div className="col-sm-4">
            <h5 className="my-4">Disney</h5>

            <h5 className="my-4">Universal Studios</h5>

            <h5 className="my-4">Dior</h5>

            <h5 className="my-4">JetBlue</h5>

            <h5 className="my-4">McDonalds</h5>

            <h5 className="my-4">NRG</h5>

            <h5 className="my-4">Warner Bros</h5>

            <h5 className="my-4">Broadcom</h5>
          </div>

          <div className="col-sm-4">
            <h5 className="my-4">NASA</h5>

            <h5 className="my-4">MGM</h5>

            <h5 className="my-4">Red Bull</h5>

            <h5 className="my-4">Mazda</h5>

            <h5 className="my-4">Samsung</h5>

            <h5 className="my-4">Sketchers</h5>

            <h5 className="my-4">Toyota</h5>

            <h5 className="my-4">...and many more</h5>
          </div>

          <div className="col-sm-4">
            <h5
              className="side-testimonial text-justify"
              style={{ color: 'grey' }}
            >
              "Thanks so much you guys - the prop is AMAZING! Client is beyond
              thrilled, said it looks exceptional :)"
            </h5>

            <h5 className="side-testimonial text-right pb-3">
              <i>J.B., Advertising Agency</i>
            </h5>
            <div className="text-center">
              <GatsbyImage
                image={
                  props.data.paulPirateShip.childImageSharp.gatsbyImageData
                }
                alt="Paul with pirate ship"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query OurClientsQuery {
    paulPirateShip: file(relativePath: { eq: "paul-pirate-ship.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1000, placeholder: BLURRED)
      }
    }
  }
`;
